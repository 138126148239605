.image-gallery>div>div:nth-child(2)>div.togglevisibility-child~div.classname_toogle_visibility_link {
    grid-column: 5 / span 1;
}

#vcardgallery-div>div>div:nth-child(2)>div.togglevisibility-child~div.classname_toogle_visibility_link {
    grid-column: 5 / span 1;
}

.carddetails-div>div:nth-child(2)>div.togglevisibility-child {
    z-index: 3;
}

.carddetails-div {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;

    font-family: var(--simple-info-font);
    font-size: 33px;
    line-height: 1.0;


/*     height: 80vh;
    width: 95vw;
    overflow-x: scroll; */

    /* background-color: hsl(0deg 0% 8% / 50%); */
}

#carddetails-div > div.card-description-expanded > div > div.card-name-div > span{
    font-family: var(--embellished-font);
    font-size: 65px;
}

.form-description{
    font-size: medium;
    margin: auto;
    justify-content: center;
    overflow-wrap: break-word;
    width: 90%;
}

.carddetails-content {
    z-index: 2;
    height: fit-content;
    margin: auto;
}

.card-description-expanded {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;

    text-indent: 60px;

/*     height: 12%;
    aspect-ratio: 6 / 1;
    overflow: scroll; */

/*     background-color: hsl(0deg 0% 25% / 50%);
    color: white; */
    z-index: 0;


    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;

    min-height: 350px;
}

/* .card-description-expanded:hover {
    height: 50%;
    aspect-ratio: 3 / 2;
} */

.carddetails-div>div:nth-child(2) {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
}

.card-type-place-div {
    grid-row: 1 / span 1;
    display: grid;
    grid-template-columns: auto 200px 5px;
    grid-template-rows: 5px 30px 30px;
}

.card-type-div {
    grid-column: 2 / span 1;
    grid-row: 2 / span 1;
}

.card-placename-div {
    grid-column: 2 / span 1;
    grid-row: 3 / span 1;
}

.card-title-desc-div {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 150px minmax(25px, 25px) auto;
    align-items: center;
}

.card-fulldescription-div {
    font-size: 18px;
    text-align: justify;
    align-self: start;
    text-indent: 100px;
    margin-top: 16px;
}

.card-time-div {
    font-family: var(--simple-info-font);
    font-size: 14px;
    padding-right: 20px;
    justify-self: right;
}

.card-map-div {
    grid-column: 2 / span 1;
}

.card-map-div iframe {
    width: 100%;
    height: 400px;

    border: 0px;
}

.image-gallery {
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(136px, 136px));
    grid-template-rows: repeat(auto-fit, minmax(136px, 136px));

    justify-content: center;

    grid-auto-flow: row dense;

    overflow-x: scroll;
}

.image-gallery>div>div {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
}

.carddetailsposts-content-vt {
    grid-column: span 2;
    grid-row: span 4;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
}

.carddetailsposts-content-hz {
    grid-column: span 4;
    grid-row: span 2;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
}

.carddetailsposts-content-sq {
    grid-column: span 2;
    grid-row: span 2;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
}

.carddetailsposts-div-hz>.post-image-hz:active,
.carddetailsposts-div-vt>.post-image-vt:active,
.carddetailsposts-div-sq>.post-image-sq:active {

    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;

    max-width: 100%;
    max-height: 100vh;
    min-height: 100vh;
    min-width: 100%;

    object-fit: contain;

    margin: auto;

    background-color: hsla(var(--blacks-extract-color), 1);
    background-color: hsla(var(--blacks-extract-color), 0.4);

    pointer-events: none;
}

.carddetailsposts-div-vt,
.carddetailsposts-div-hz,
.carddetailsposts-div-sq {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
}

.post-image-vt {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;

    width: 272px;
    aspect-ratio: 1 / 2;

    object-fit: cover;

    margin: auto;
    /* z-index: 1; */
}

.post-image-hz {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;

    height: 272px;
    aspect-ratio: 2 / 1;

    object-fit: cover;

    margin: auto;
    /* z-index: 1; */
}

.post-image-sq {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;

    width: 272px;
    aspect-ratio: 1 / 1;

    object-fit: cover;

    margin: auto;
}

.like-div {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    display: grid;
    grid-template-columns: 42px 42px;
    grid-template-rows: 42px;

    align-items: center;
    justify-self: end;
    align-self: end;

    margin: 20px;

    /* z-index: 3; */
}

.like-div>a {
    width: 42px;
    height: 42px;

    grid-row: 1 / span 1;
    grid-column: 2 / span 1;
}

.like-div>span {
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;

    /* z-index: 5; */

    font-family: var(--simple-info-font);
    color: hsla(var(--whites-extract-color), 1);
    font-size: 16px;
    text-align: center;
}

.overlay-post {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;

    width: 100%;
    height: 100%;

    background-color: hsla(var(--blacks-extract-color), 0.2);
    background: linear-gradient(to bottom, transparent 0%, transparent 50%, hsla(var(--blacks-extract-color), 0.2) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='hsla(var(--blacks-extract-color), 1)', endColorstr='hsla(var(--blacks-extract-color), 1)', GradientType=0);

    /* z-index: 2; */
}

.post-title {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;

    color: hsla(var(--whites-extract-color), 1);
    font-family: var(--simple-info-font);
    font-size: 24px;
    align-self: end;
    justify-self: center;

    padding: 50px 0px;

    /* z-index: 3; */
}

.card-span {
    background-color: var(--error-or-notloggedin-color);
    color: white;
    font-family: var(--simple-info-font);
    font-size: 24px;
    justify-self: center;

    padding: 3px;
    border: hsla(var(--blacks-extract-color), 1);
    border-radius: 5px;

    /* z-index: 99; */
}

/* ATENTION_HERE, came from portfolio.css */

.cardset-content {
    margin: auto;
    grid-column: 1 / span 1;
    grid-row: 3 / span 1;
}

.card-div {

    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;

    box-shadow: 0 4px 8px 0 hsla(var(--blacks-extract-color), 0.2), 0 6px 20px 0 hsla(var(--blacks-extract-color), 0.2);
}

.card-div:hover {
    box-shadow: 0 1px 2px 0 hsla(var(--my-dark-gray-extract-color), 0.2), 0 1px 5px 0 hsla(var(--blacks-extract-color), 0.2);
    /* transform: scale(0.99); */
}

.card-link {

    grid-column: 1 / span 1;
    grid-row: 1 / span 1;

    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
}

.card-image {
    grid-column: 1 /span 1;
    grid-row: 1 / span 1;

    object-fit: cover;
    object-position: 100% 100%;
}

.card-overlay {
    grid-column: 1 /span 1;
    grid-row: 1 / span 1;

    background: -moz-linear-gradient(top, transparent 0%, transparent 50%, hsla(var(--blacks-extract-color), 1) 100%);
    background: -webkit-linear-gradient(top, transparent 0%, transparent 50%, hsla(var(--blacks-extract-color), 1) 100%);
    background: linear-gradient(to bottom, hsla(var(--blacks-extract-color), 0.6) 0%, transparent 50%, hsla(var(--blacks-extract-color), 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='transparent', endColorstr='hsla(var(--blacks-extract-color), 1)', GradientType=0);
}

.card-overlay:hover {
    background: transparent;
}

.card-description {
    grid-column: 1 /span 1;
    grid-row: 1 / span 1;

    color: hsla(var(--whites-extract-color), 1);
    font-size: 18px;
    font-family: var(--simple-info-font);
    text-align: center;

    transition: 0.4s;

    display: grid;
    grid-template-columns: 10px auto auto 10px;
    grid-template-rows: 10px 24px auto 24px 24px 10px;
}



.card-title {
    grid-column: 2 / span 1;
    grid-row: 4 / span 1;

    text-align: left;
}

.card-eventbegindate {
    grid-column: 3 / span 1;
    grid-row: 5 / span 1;

    font-size: 16px;
    text-align: right;
}

.card-eventtype {
    grid-column: 2 / span 2;
    grid-row: 2 / span 1;
}


.card-inicio {
    grid-column: 1 / span 1;
    grid-row: 5 / span 2;

    transform-origin: 50% 50% 0;

    align-self: center;
    justify-self: center;

    transform: rotate(-90deg);

    font-size: 16px;
}

.card-fim {
    grid-column: 4 / span 1;
    grid-row: 5 / span 2;

    transform-origin: 50% 50% 0;

    align-self: center;
    justify-self: center;

    transform: rotate(-90deg);

    font-size: 16px;
}

.cardPost {
    display: grid;
    grid-template-columns: 1fr 6fr;

    background-color: var(--background-color);

    margin: 10px;
    border-radius: 6px;
    box-shadow: 0 4px 8px 0 hsla(var(--blacks-extract-color), 0.2), 0 6px 20px 0 hsla(var(--blacks-extract-color), 0.2);
}

.cardPost:hover {
    box-shadow: 0 1px 2px 0 hsla(var(--my-dark-gray-extract-color), 0.2), 0 1px 5px 0 hsla(var(--blacks-extract-color), 0.2);
    transform: scale(1.02);
}

.cardPost-card {
    padding: 10px;
    /* z-index: 2; */
}

.cardPost-Post {
    margin: 20px;
}

#hcard-div img {
    width: 333px;
    height: 240px;
}

#scard-div img {
    width: 240px;
    height: 240px;
}

#hcardgallery-div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column: 2 / span 1;

    justify-items: center;

    width: 1029px;
}

#scardgallery-div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column: 2 / span 1;

    justify-items: center;

    width: 1250px;
}

#vcardgallery-div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 240px));
    grid-gap: 10px;

    justify-items: center;
    padding: 10px 0px 10px 0px;
}

#vcardgallery-div>div {
    width: 240px;
    height: 333px;
}

#vcardgallery-div .card-image {
    width: inherit;
    height: inherit;
}

/* #vcardgallery-div .classname_toogle_visibility_link{
    width: unset;
    height: unset;
}

#vcardgallery-div .togglevisibility-child {
    width: unset;
    height: unset;
} */



@media only screen and (max-width: 800px) {
    .carddetails-div {
        display: grid;
        grid-template-columns: 1fr;
    }

    .card-map-div {
        grid-column: 1 / span 1;
    }

    .image-gallery {
        grid-column: 1 / span 1;
    }
}

@media only screen and (max-width: 700px) {

    .carddetails-div {
        font-size: 26px;
    }

    .card-type-place-div {
        grid-template-columns: auto 150px 5px;
        grid-template-rows: 5px 24px 30px;
    }

    .card-title-desc-div {
        grid-template-rows: 90px 54px 90px;
    }

    .card-description-expanded {
        height: auto;
    }

    .card-name-div {
        font-size: 45px;
    }

    .card-time-div {
        font-size: 12px;
        padding-left: 25px;
    }

    .card-fulldescription-div {
        font-size: 14px;
        text-indent: 24px;

        padding: 0px 18px 0px 18px;
    }

    .image-gallery {
        grid-gap: 2px;
    }

    .carddetailsposts-content-vt {
        grid-column: span 1;
        grid-row: span 2;
    }

    .carddetailsposts-content-hz {
        grid-column: span 2;
        grid-row: span 1;
    }

    .carddetailsposts-content-sq {
        grid-column: span 1;
        grid-row: span 1;
    }

    .post-image-vt {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;

        /* width: 160px;
        height: 322px; */

        object-fit: cover;
        /* z-index: 1; */
    }

    .post-image-hz {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;

        /* width: 322px;
        height: 160px; */

        object-fit: cover;
        /* z-index: 1; */
    }

    .post-image-sq {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;

        /* width: 162px;
        height: 162px; */

        object-fit: cover;
        /* z-index: 1; */
    }

    .post-title {
        font-size: 12px;

        padding: 25px 0px;
    }
}