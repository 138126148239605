
input, textarea, select, option{
    
    background: hsla(var(--whites-extract-color), 1);
    
    color: hsla(var(--accent-extract-color), 1);
    font-family: var(--simple-info-font);
    
    width: 90%;
    width: -webkit-fill-available; 
    width: -moz-available;
    
    padding: 8px 16px 8px 16px;
    border-radius: 8px;
    border: 1px solid hsla(var(--featured-extract-color), 0.4);

    box-shadow: 0 0px 0px 0 hsla(var(--blacks-extract-color), 0.2), 0 1px 4px 0 hsla(var(--blacks-extract-color), 0.2);

    -webkit-appearance: none;
}

textarea:focus, input:focus{
    outline: none;
}

input[type=date] {
    color: hsla(var(--featured-extract-color), 1);
}

input[type=number] {
    -moz-appearance: textfield;
}

input[type=date], input[type=time],input[type=checkbox]{
    width: fit-content;
    max-height: 15px;
}

input[type=file] {
    background: transparent;

    color: transparent;
    font-family: var(--simple-info-font);
    justify-self: center;

    width: auto;
    width: 112px;
    height: 24px;

    padding: 5px;
    border-radius: 8px;
    border: none;

    box-shadow: none;
    -webkit-appearance: none;
}

input[type=file]::-webkit-file-upload-button {
    visibility: hidden;
}

input[type=file]::before {

    content: 'Selecionar Ficheiro';
    display: inline-block;
    
    background: hsla(var(--accent-extract-color), 1);

    padding: 5px 8px;
    border: 1px solid hsla(var(--accent-extract-color), 1);
    border-radius: 3px;
    outline: none;

    font-size: 10pt;
    font-weight: 700;
    white-space: nowrap;
    cursor: pointer;
    text-shadow: 1px 1px hsla(var(--whites-extract-color), 1);

    -webkit-user-select: none;
    user-select: none;
}

input[type=file]:hover::before {
    border-color: hsla(var(--featured-extract-color), 1);
}

input[type=file]:active::before {
    background: -webkit-linear-gradient(to top, hsla(var(--my-light-gray-extract-color), 1), hsla(var(--my-light-gray-extract-color), 1));
}

[type=checkbox]:checked + img, [type=checkbox]:checked {

    background: var(--background-color);

    outline: none !important;
    box-shadow: 0 0 0px 3px hsla(var(--featured-extract-color), 1);
}

input[type=date]::-webkit-inner-spin-button, input[type=time]::-webkit-inner-spin-button, input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
}

input#terms{
    width: 16px;
    height: 16px;

    padding: 0px;
    margin: 0px 0px -1px 16px;
}
input#terms:checked{
    background: hsla(var(--featured-extract-color), 1);

    outline: none !important;
    box-shadow: 0 0 0px 1px hsla(var(--featured-extract-color), 1);
}



::placeholder {
    color: hsla(var(--featured-extract-color), 1);
    opacity: 1;
}

:-ms-input-placeholder { 
    color: hsla(var(--featured-extract-color), 1);
}

::-ms-input-placeholder {
    color: hsla(var(--featured-extract-color), 1);
}

.int_check_in input[type=checkbox]{
    text-align: center;
    justify-self: center;

    width: 16px;
    height: 16px;

    padding: 0px;
    margin: 0px 0px -1px 16px;
}

.int_check_in input[type=checkbox]:checked{
    background: hsla(var(--featured-extract-color), 1);

    outline: none !important;
    box-shadow: 0 0 0px 1px hsla(var(--featured-extract-color), 1);
}

.morada_in input, .email_in input, .capacidade_in input, .fieldarray input, .fieldarray select, .coordenadas_in{
    width: -moz-available;
    width: -webkit-fill-available;
}

@media only screen and (max-width: 670px) {

    input[type=date]{
        width: -moz-available; 
        width: -webkit-fill-available; ;
    }

}
