
ul, li{
    list-style: none;

    padding: 0px;
    margin: 5px 0px;
}

form{
    background-color: var(--background-color);

    max-width: 100%;

    border: 1px solid hsla(var(--featured-extract-color), 1);
    margin: 1px;

    box-shadow: 1px 1px 5px -3px hsla(var(--blacks-extract-color), 1);
    -webkit-box-shadow: 1px 1px 5px -3px hsla(var(--blacks-extract-color), 1);
    -moz-box-shadow: 1px 1px 5px -3px hsla(var(--blacks-extract-color), 1);

    display: grid;
    grid-template-rows: auto auto auto;
    min-width: fit-content;
    max-width: 80vw;
}

form *{
    color: hsla(var(--featured-extract-color), 1);
    font-family: var(--semi-embellished-font);
}

form label{
    font-size: 18px;
    justify-self: center;
    align-self: center;
}

form button {
    display: inline-block;

    padding: 10px;
    border: none;
    margin: 0;
    margin: 10px;

    color: hsla(var(--whites-extract-color), 1);
    font-family: var(--simple-info-font);
    text-decoration: none;
    text-align: center;
    cursor: pointer;

    background-color: hsla(var(--featured-extract-color), 1);

    transition: background 250ms ease-in-out, 
                transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;

}

form button:hover, form button:focus {
    background: hsla(var(--featured-extract-color), 0.6);
}

form button:focus {
    outline: 1px solid hsla(var(--whites-extract-color), 1);
    outline-offset: -4px;
}

form button:active {
    transform: scale(0.99);
}



.form-title{
    text-align: center;
    font-size: 20px;
}

.form-title *{
    margin: 12px;
}

.isSignIn_true form, .isSignIn_false form{
    border: none;
    margin: 40px;
}

.isSignIn_true form{
    background-color: hsla(var(--sucess-or-loggedin-extract-color), 0.2);
}

.isSignIn_false form{
    background-color: hsla(var(--error-or-notloggedin-extract-color), 0.2);
}

.buttons-form{
    display: grid;
    grid-template-columns: 1fr 1fr;

    width: 100%;

    align-self: center;
    justify-self: center;
}

.input-div{
    display: grid;
    grid-template-columns: 1fr;

    align-self: center;
}

.input-div > div{
    padding: 0px 20px 0px 20px;
}

.fileInput_div{
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
}

.weekday_time, .horas, .time_in_div, .selectable-images, .in_pagamentos{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-template-rows: auto;
}

.boximage + img {
    cursor: pointer;
}

.image-radiobox > label {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
}

.image-radiobox > label > input,
.image-radiobox > label > img{
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
    justify-items: center;
    align-items: center;
}

.selectable-images * , .in_pagamentos *{
    padding: 0px;
    margin: 0px;
    margin: 10px;

    text-align: center;
}

.boximage {

    position: absolute;
    opacity: 0;

    width: 0;
    height: 0;
    box-sizing: border-box;
}

.accordion {
    background-color: hsla(var(--featured-extract-color), 1);

    color: hsla(var(--whites-extract-color), 1);
    text-align: left;
    cursor: pointer;

    padding: 18px;
    border: none;
    outline: none;
    margin: 2px;

    transition: 0.4s;
}

.accordion:active, .accordion:hover {
    background: hsla(var(--featured-extract-color), 0.6);

    outline: none !important;
    border: none;
}

.morada_in{
    display:grid;
    grid-template-columns: 80px auto auto 80px 80px;
    grid-template-rows: auto auto auto auto auto auto;
    grid-gap: 3px;
}

.morada_in > label{
    grid-row: 1 / span 1;
    grid-column: 1 / span 5;
}

.arteria_in{
    display: grid;
    grid-row: 2 / span 1;
    grid-column: 1 / span 3;
    grid-template-columns: 80px auto;
}

.identificacao_porta_in{
    grid-row: 2 / span 1;
    grid-column: 4 / span 1;
}

.identificacao_alojamento_in{
    grid-row: 2 / span 1;
    grid-column: 5 / span 1;
}

.referencia_auxiliar_in{
    grid-row: 3 / span 1;
    grid-column: 1 / span 5;
}

.localidade_in{
    grid-row: 4 / span 1;
    grid-column: 1 / span 5;
}

.codigo_postal_in{
    display: grid;

    grid-row: 5 / span 1;
    grid-column: 1 / span 5;

    grid-template-columns: 150px auto;
    grid-gap: 7px;
}

.numero_postal_in{
    display: grid;

    grid-template-columns: 75px 75px;
    grid-gap: 3px;
}

.tipo_arteria_in{
    width: 76px;
}

.coordenadas_in{
    grid-row: 6 / span 1;
    grid-column: 1 / span 5;
}

.divideinsix, .divideintwo{
    display: grid;

    grid-auto-flow: column;
    grid-template-rows: auto;
}

.addeventstartdate, .addeventstarthour, .addeventenddate, .addeventendhour{
    justify-self: center;
}

.addeventlocal, .addeventtype, .addeventprice, .addeventcapacity{
    margin: 0px 5px;
}

.form-error{
    color: hsla(var(--error-or-notloggedin-extract-color), 1);
    font-family: var(--simple-info-font);
    font-size: 12px;
}

.buttons-form div{
    justify-self: center;
}



#form-div-registerform, #form-div-editform{
    margin: 20px 50px;
}

#input-div-2-registerform, #input-div-2-editform{
    display: grid;

    grid-template-columns: 1fr auto;
    grid-gap: 5px;
}

#input-div-2-registerform > .dob input, #input-div-2-editform > .dob input{
    max-height: 15px;
}





@media only screen and (max-width: 670px) {

    .input-div-3 *{
        display: initial;
    }

    #input-div-2-registerform{
        display: initial;
    }

    #form-div-registerform, #form-div-editform{
        margin: 0px;
    }
}
