
.footer-div{
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-template-rows: auto auto;
  background-color:hsla(var(--my-light-gray-extract-color), 1);
  box-shadow: 0px -1px 15px hsla(var(--blacks-extract-color), 0.2);
  text-align: center;
  color: hsla(var(--blacks-extract-color), 1);
  font-family: var(--semi-embellished-font);
}

.footer-div a, .footer-div p{
    align-self: center;
    padding: 1vw;
    transition: 0.4s;
}

.footer-div a:active{
    background: hsla(var(--whites-extract-color), 1);
    outline: none !important;
    border: none;
}

.footer-div a:hover{
  background: hsla(var(--whites-extract-color), 1);
  color: hsla(var(--blacks-extract-color), 1);
  outline: none !important;
  border: none;
}

.footer-div a:focus{
  background: hsla(var(--whites-extract-color), 1);
  outline: none !important;
}

.by{
    display: grid;
    grid-column: 2 / span 3;
    grid-row: 2 / span 1;
}
