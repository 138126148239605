
h1,h2,h3,h4,h5{
    font-family: var(--embellished-font);
    line-height: 1.0;
    font-weight: 400;
    
    margin: 0px;
}

h1,h2,h3,h4,h5,p,span{

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

/*     color: white;
    mix-blend-mode: difference; */
}


.invisible_element{
    display: none;
}

a:link, a:visited, a:hover, a:active {
    text-decoration: none;
    color: inherit;
}

.page_signin_div{
    display: grid;
}
.center_button{
    justify-self: center;
    margin-bottom: 50px;
    background-color: hsla(var(--warning-or-caution-extract-color), 1);
    color: hsla(var(--blacks-extract-color), 1);
}

.fullscreen-text-description{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
}

.fullscreen-div{
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
}

.fullscreen-img{
    width: 100%;
    height: 300vh;
    object-fit: cover;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.overlay2{
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    background-color: hsla(var(--blacks-extract-color), 0.6);
    color: hsla(var(--whites-extract-color), 1);
    font-family: var(--simple-info-font);
    z-index: 2;
}

.overlay2 div{
    margin: 20px;
    margin-top: 50px;
    margin-bottom: 200px;
}

.ident{
    margin-left: 20px;
}

.accountmanager-notloggedin-div, .accountmanager-loggedin-div{
    color: hsla(var(--whites-extract-color), 1);
    font-family: var(--simple-info-font);
    position: absolute;
    z-index: 99;
    top: 0;
    right: 35px;
    padding: 10px;
    margin: 5px;
}

.accountmanager-loggedin-div{
    background-color: hsla(var(--sucess-or-loggedin-extract-color), 0.2);
}

.accountmanager-notloggedin-div {
    background-color: hsla(var(--error-or-notloggedin-extract-color), 0.2);
}

.accountmanager-notloggedin-div a:hover, .accountmanager-loggedin-div a:hover{
    text-decoration: underline;
}

.delete_button{
    border: none;
    text-decoration: none;
    color: hsla(var(--error-or-notloggedin-extract-color), 1);
    cursor: pointer;
    text-align: center;
    -moz-appearance: none;
    font-family: var(--simple-info-font);
    padding: 10px;
    
    position: fixed;
    z-index: 1;
    left: 25%;
    bottom: 0;
    overflow: auto;
    background-color: darkred;
}

.nav-search-div{
    width: 95vw;
    margin: auto;
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
}

.event-list > .cardset-content > #vcardgallery-div{
    justify-content: space-evenly;
}

:focus-visible {
    outline: auto 1px;
}

@media only screen and (max-width: 670px) {

    .togglevisibility-child > div > div > div > div > button{
        padding: 0px;
    }
    .togglevisibility-child > .modal_close > button{
        padding: 10px;
    }
    .togglevisibility-div > div.togglevisibility-child > div.modal > form{
        max-width: none;
    }
    .form-div, .form-div3{
        width: unset;
    }
    .togglevisibility-child > .modal_close{
        top: 110px;
        right: 0vw;
    }
    .field > .divideinsix,.field > .divideintwo {
        display: grid;
        grid-auto-flow: unset;
        grid-template-rows: auto;
    }
    .divideintwo > .addeventstartdate,.divideintwo > .addeventstarthour,.divideintwo > .addeventenddate,.divideintwo > .addeventendhour {
        justify-self: unset;
    }
    input[type=date], input[type=time], input[type=checkbox] {
        width: auto;
    }
    .page_signin_div > .presentation-div{
        margin-bottom: 40vh;
    }
    .input-div-1 > .morada_in{
        display: unset;
    }
    .morada_in > .codigo_postal_in{
        display: unset;
    } 
    #input-div-2-editform > div:nth-child(1){
        align-self: end;
    }.carddetails-div > .image-gallery{
        display: unset;
    }
    .carddetailsposts-div-vt > .post-image-vt {
        width: 100%;
    }
    .carddetailsposts-div-hz > .post-image-hz {
        width: 100%;
    }
    .carddetailsposts-div-sq > .post-image-sq {
        width: 100%;
    }

    .sucess-message-true, .warning-message-true, .error-message-true{
        margin: 0;
    }

    .portfolio-div > .portfolio-description {
        grid-template-rows: minmax(0px, 210px) auto auto auto auto auto;
    }
}

