
:root {
  --featured-color: hsla(var(--featured-extract-color), 1);
  --accent-color: hsla(var(--accent-extract-color), 1);
  --my-dark-gray-color: hsla(var(--my-dark-gray-extract-color), 1);
  --my-light-gray-color: hsla(var(--my-light-gray-extract-color), 1);
  --blacks-color: hsla(var(--blacks-extract-color), 1);
  --whites-color: hsla(var(--whites-extract-color), 1);

  --featured-extract-color: 46, 30%, 54%;
  --accent-extract-color: 28, 54%, 24%;
  --my-dark-gray-extract-color: 0, 0%, 53%;
  --my-light-gray-extract-color: 0, 0%, 93%;
  --blacks-extract-color: 0, 0%, 0%;
  --whites-extract-color: 0, 0%, 100%;

  --background-color: hsl(36, 38%, 97%);

  --error-or-notloggedin-color: hsla(var(--error-or-notloggedin-extract-color), 1);
  --sucess-or-loggedin-color: hsl(var(--sucess-or-loggedin-extract-color), 1);
  --warning-or-caution-color: hsla(var(--warning-or-caution-extract-color), 1);

  --error-or-notloggedin-extract-color: 0, 100%, 50%;
  --sucess-or-loggedin-extract-color: 120, 100%, 50%;
  --warning-or-caution-extract-color: 60, 100%, 50%;

  --embellished-font: 'TheGlamorousTale';
  --semi-embellished-font: 'AmaticSC';
  --simple-info-font: 'pakenham';
  /*fonts:
      pakenham
      TheGlamorousTale
      AmaticSC
      Brightest
      Stoner
      ClassyBeautiful2-Regular
      Cookie
      Nevrada
      RoseGarden-Regular
      ClassyBeautiful2
      AdventPro-ExtraLight
      BernardoModa
      data-latin
      Parisienne
      Rochester*/

}

body {
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.error-message-true {
  position: fixed;
  top: 0;
  width: 50%;
  margin: 0% 25%;
  z-index: 99;
  text-align: center;
  background-color: var(--error-or-notloggedin-color);

  opacity: 0;
  animation: fadeOut ease 12s;
  -webkit-animation: fadeOut ease 12s;
  -moz-animation: fadeOut ease 12s;
  -o-animation: fadeOut ease 12s;
  -ms-animation: fadeOut ease 12s;
}

.warning-message-true {
  position: fixed;
  top: 0;
  width: 50%;
  margin: 0% 25%;
  z-index: 99;
  text-align: center;
  background-color: var(--warning-or-caution-color);

  opacity: 0;
  animation: fadeOut ease 12s;
  -webkit-animation: fadeOut ease 12s;
  -moz-animation: fadeOut ease 12s;
  -o-animation: fadeOut ease 12s;
  -ms-animation: fadeOut ease 12s;
}

.sucess-message-true {
  position: fixed;
  top: 0;
  width: 50%;
  margin: 0% 25%;
  z-index: 99;
  text-align: center;
  background-color: var(--sucess-or-loggedin-color);

  opacity: 0;
  animation: fadeOut ease 12s;
  -webkit-animation: fadeOut ease 12s;
  -moz-animation: fadeOut ease 12s;
  -o-animation: fadeOut ease 12s;
  -ms-animation: fadeOut ease 12s;
}

.sucess-message-true,
.warning-message-true,
.error-message-true {
  font-family: var(--simple-info-font);
  color: white;
}




* {
  scrollbar-width: thin;
  scrollbar-color: hsla(var(--my-light-gray-extract-color), 1) transparent;
}
::-webkit-scrollbar{
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb:vertical  {
  background-color: hsla(var(--featured-extract-color), 1)
}
::-webkit-scrollbar-thumb:horizontal  {
  background-color: hsla(var(--featured-extract-color), 1)
}



@font-face {
  font-family: pakenham;
  font-style: normal;
  font-weight: normal;
  src: local(pakenham), local(pakenham),
       url('../content/fonts/pakenham\ rg.ttf') format('TrueType')
}

@font-face {
  font-family: 'TheGlamorousTale';
  font-style: normal;
  font-weight: normal;
  src: local('TheGlamorousTale'), local('TheGlamorousTale'),
       url('../content/fonts/TheGlamorousTale-Regular.otf') format('OpenType'),
       url('../content/fonts/TheGlamorousTale-Regular.ttf') format('TrueType');
}

@font-face {
  font-family: 'AmaticSC';
  font-style: normal;
  font-weight: 400;
  src: local('AmaticSC'), local('AmaticSC'),
       url('../content/fonts/AmaticSC-Regular.eot?#iefix') format('embedded-opentype'),
       url('../content/fonts/AmaticSC-Regular.woff2') format('woff2'),
       url('../content/fonts/AmaticSC-Regular.woff') format('woff'),
       url('../content/fonts/AmaticSC-Regular.ttf') format('truetype'),
       url('../content/fonts/AmaticSC-Regular.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Cookie';
  font-style: normal;
  font-weight: 400;
  src: local('Cookie'), local('Cookie'),
       url('../content/fonts/Cookie-Regular.eot?#iefix') format('embedded-opentype'),
       url('../content/fonts/Cookie-Regular.woff2') format('woff2'),
       url('../content/fonts/Cookie-Regular.woff') format('woff'),
       url('../content/fonts/Cookie-Regular.ttf') format('truetype'),
       url('../content/fonts/Cookie-Regular.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Nevrada';
  font-style: normal;
  font-weight: normal;
  src: local('Nevrada'), local('Nevrada'),
       url('../content/fonts/ALNevrada-Normal.otf') format('OpenType'),
       url('../content/fonts/ALNevrada-Normal.ttf') format('TrueType');
}

@font-face {
  font-family: 'Brightest';
  font-style: normal;
  font-weight: normal;
  src: local('Brightest'), local('Brightest'),
       url('../content/fonts/brightest-Regular.otf') format('OpenType'),
       url('../content/fonts/brightest-Regular.ttf') format('TrueType');
}

@font-face {
  font-family: 'Stoner';
  font-style: normal;
  font-weight: normal;
  src: local('Stoner'), local('Stoner'),
       url('../content/fonts/stoner-Regular.otf') format('OpenType'),
       url('../content/fonts/stoner-Regular.ttf') format('TrueType');
}

@font-face {
  font-family: 'RoseGarden-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('RoseGarden-Regular'), local('RoseGarden-Regular'),
       url('../content/fonts/RoseGarden-Regular.otf') format('OpenType'),
       url('../content/fonts/RoseGarden-Regular.ttf') format('TrueType');
}

@font-face {
  font-family: 'ClassyBeautiful2-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('ClassyBeautiful2-Regular'), local('ClassyBeautiful2-Regular'),
       url('../content/fonts/ClassyBeautiful2-Regular.otf') format('OpenType'),
       url('../content/fonts/ClassyBeautiful2-Regular.ttf') format('TrueType');
}

@font-face {
  font-family: 'ClassyBeautiful2';
  font-style: normal;
  font-weight: normal;
  src: local('ClassyBeautiful2'), local('ClassyBeautiful2'),
       url('../content/fonts/ClassyBeautiful2-Regular.ttf') format('TrueType')
}

@font-face {
  font-family: 'AdventPro-ExtraLight';
  font-style: normal;
  font-weight: normal;
  src: local('AdventPro-ExtraLight'), local('AdventPro-ExtraLight'),
       url('../content/fonts/AdventPro-ExtraLight.ttf') format('TrueType')
}

@font-face {
  font-family: 'BernardoModa';
  font-style: normal;
  font-weight: normal;
  src: local('BernardoModa'), local('BernardoModa'),
       url('../content/fonts/Bernardo\ Moda.ttf') format('TrueType')
}

@font-face {
  font-family: 'data-latin';
  font-style: normal;
  font-weight: normal;
  src: local('data-latin'), local('data-latin'),
       url('../content/fonts/data-latin.ttf') format('TrueType')
}

@font-face {
  font-family: 'Parisienne';
  font-style: normal;
  font-weight: 400;
  src: local('Parisienne'), local('Parisienne'),
       url('../content/fonts/Parisienne-Regular.eot?#iefix') format('embedded-opentype'),
       url('../content/fonts/Parisienne-Regular.woff2') format('woff2'),
       url('../content/fonts/Parisienne-Regular.woff') format('woff'),
       url('../content/fonts/Parisienne-Regular.ttf') format('truetype'),
       url('../content/fonts/Parisienne-Regular.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Rochester';
  font-style: normal;
  font-weight: 400;
  src: local('Rochester'), local('Rochester'),
       url('../content/fonts/Rochester-Regular.eot?#iefix') format('embedded-opentype'),
       url('../content/fonts/Rochester-Regular.woff2') format('woff2'),
       url('../content/fonts/Rochester-Regular.woff') format('woff'),
       url('../content/fonts/Rochester-Regular.ttf') format('truetype'),
       url('../content/fonts/Rochester-Regular.svg#OpenSans') format('svg');
}
